<template>
    <figure class="logo-small">
        <a href="#intro">
            <img
                src="../assets/Logo1.svg"
                alt="Logo with teal capital P and J letters"
        /></a>
    </figure>
</template>

<script>
export default {};
</script>

<style>
.logo-small a {
    width: 50px;
    display: flex;
    align-items: center;
}

.logo-small img {
    width: 100%;
    object-fit: cover;
}
</style>